// eslint-disable-next-line camelcase, import/no-extraneous-dependencies
import { Open_Sans } from 'next/font/google';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { green, grey, pink } from '@mui/material/colors';

const opensans = Open_Sans({
  weight: ['300', '400', '500', '600', '700', '800'],
  subsets: ['latin'],
});

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: opensans.style.fontFamily,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            scrollBehavior: 'smooth',
          },

          body: {
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
          },
        },
      },
    },

    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
        xxl: 2180,
      },
    },

    palette: {
      common: { black: 'rgba(0, 0, 0, 0.8)', white: '#fff' },
      background: {
        primary: {
          light: pink[50],
          main: '#ED6A6D',
          dark: '#2A1D1D',
        },

        secondary: {
          main: grey.A100,
          dark: grey[900],
        },

        paper: '#fff',
        mediaPress: '#958E8E',
        darkBlue: '#0E253C',
      },

      primary: {
        light: '#F25D60',
        main: '#ED6A6D',
        dark: '#C63134',
        contrastText: '#fff',
      },

      secondary: {
        light: 'rgba(42, 29, 29, 0.7)',
        main: 'rgba(42, 29, 29, 0.9)',
        dark: 'rgba(42, 29, 29, 1)',
        contrastText: '#fff',
      },

      text: {
        primary: '#2A1D1D',
        secondary: 'rgb(117, 117, 117)',
        disabled: 'rgba(0, 0, 0, 0.38)',
        hint: 'rgba(0, 0, 0, 0.38)',
      },

      border: {
        main: grey[300],
      },

      productsColor: {
        class: '#367CE7',
        talk: '#F5C32E',
        abroad: '#ED6A6D',
      },

      success: {
        light: green[100],
        main: green[500],
        dark: green[900],
        contrastText: '#fff',
      },

      statuses: {
        cancelled: '#f44336',
        cancelledbystudent: '#f44336',
        completed: '#4caf50',
        interrupted: '#f44336',
        nostudent: '#b3f97d',
        notalker: '#808080',
        online: '#4caf50',
        partiallycompleted: '#4caf50',
        scheduled: '#2196f3',
        suspended: '#f44336',
      },
    },
  })
);

export default theme;
